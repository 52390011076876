<template>
	<div id="landTypeList">
		<page-adopt-type></page-adopt-type>
	</div>
</template>

<script>
	import pageAdoptType from '@/components/layout/adopt/page-adopt-type.vue'
	export default {
		components:{
			pageAdoptType
		}
	}
</script>

<style lang="scss">
</style>
